<template>
  <div>
    <!-- HANDELBAY INNOVATIVE -->
    <section id="buyers">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-sm-7 col-md-offset-1 text-left">
            <div class="title" itemscope :itemtype="global.url">
              <h2 class="title-1" itemprop="HandelBay seguridad">
                {{ $t('lang.buyers.do_you_want_ease_security') }}
              </h2>

              <h3 class="title-2" itemprop="HandelBay transparancia">
                {{ $t('lang.buyers.and_savings_on_purchases?') }}
              </h3>
              <span class="handelbay-active"></span>
            </div>
            <div class="handelbay-text" itemscope :itemtype="global.url">
              <p itemprop="HandelBay es la solución">
                {{ $t('lang.buyers.handelbay_is_a_business_shopping_platform') }}
              </p>
            </div>
            <br>

            <div class="title-footer">
              <a href="#" class="btn no-margin-left btn-handelbay-success btn-handelbay-black" data-toggle="modal" data-target="#modal-demo">
                {{ $t('lang.buyers.schedule_your_demo_now') }}
              </a>
            </div>
          </div>
          <div class="col-md-5 col-sm-5">
            <div class="computers">
              <img src="../assets/images/landing/handelbay/handelbay_quieres_ahorros.png" alt="Es hora de usar HandelBay en tu día a día">
            </div>
          </div>
        </div>
        <!-- .row -->
      </div>
      <!-- .container -->
    </section>
    <!-- END HANDELBAY INNOVATIVE -->
    <!-- .handelbay-background-curva -->
    <section id="handelbay-background-curva" class="handelbay-background-curva-buyers">
    </section>
    <!-- .handelbay-background-curva -->
    <!-- Ahorros en las compras -->
    <section id="savings_in_purchases" class="savings_in_purchases">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-sm-5 col-md-offset-1 col-sm-offset-0 col-xs-6 col-xs-offset-3 ">
            <div class="img_man">
              <img src="../assets/images/landing/handelbay/handelbay_man_delivery.png" alt="Es hora de usar HandelBay en tu día a día">
            </div>
          </div>
          <div class="col-md-6 col-sm-7 text-right col-xs-12">
            <div class="handelbay-text" style="padding-bottom:18px;" itemscope :itemtype="global.url">
              <h2 class="title">
                <b>
                  {{ $t('lang.buyers.shopping_savings') }}
                </b>
              </h2>

              <p class="text" itemprop="HandelBay es la solución">
                {{ $t('lang.buyers.can_you_imagine_being_able_to_save_up_to_70_of_the_time') }}
              </p>

              <p class="text">
                {{ $t('lang.buyers.you_can_have_savings_on_your_business_purchases') }}
              </p>

              <h3 class="title space-title">
                <b>
                  {{ $t('lang.buyers.verified_providers') }}
                </b>
              </h3>

              <p class="text">
                {{ $t('lang.buyers.at_HandelBay_we_verify_the_documentation_of_companies') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End ahorros en las compras -->
    <!-- Red de expansión -->
    <section id="expansion_network">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-sm-4 col-md-offset-1 text-left">
            <h2 class="title">
              <b>
                {{ $t('lang.buyers.expanding_network') }}
              </b>
            </h2>

            <p class="text" itemprop="HandelBay es la solución">
              {{ $t('lang.buyers.have_you_thought_if_the_product_you_need_has_a_company') }}
            </p>

            <p class="text">
              {{ $t('lang.buyers.handelbay_expanding_network_of_companies_allows_you_this_and_much_more') }}
            </p>
          </div>
          <div class="col-md-2 col-sm-4 col-xs-6 col-xs-offset-3 col-sm-offset-0">
            <div class="brand">
              <img src="../assets/images/landing/handelbay/handelbay_img_center.png" alt="Es hora de usar HandelBay en tu día a día">
            </div>
          </div>
          <div class="col-md-4 col-sm-4 text-right col-xs-12">
            <div class="handelbay-text" itemscope itemtype="https://handelbay.com/">
              <h3 class="title">
                <b>
                  {{ $t('lang.buyers.social_network_and_ratings') }}
                </b>
              </h3>

              <p class="text" itemprop="HandelBay red empresarial">
                {{ $t('lang.buyers.handelbay_is_a_business_social_network') }}
              </p>

              <p class="text">
                {{ $t('lang.buyers.in_handelbay_you_will_be_able_to_know_the_qualification_and_reputation_of_the_company') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Red de expansión -->
    <!-- Compara cotizaciones y proveedores -->
    <section id="compare_quotes" class="text-left">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-sm-7 col-md-offset-1">
            <div class="handelbay-text" itemscope :itemtype="global.url">
              <h2 class="title">
                <b>
                  {{ $t('lang.buyers.compare_quotes_and_providers') }}
                </b>
              </h2>

              <p class="text" itemprop="HandelBay es la solución">
                {{ $t('lang.buyers.can_you_imagine_making_a_comparative_chart_of_the_prices?') }}
              </p>

              <h3 class="title space-title">
                <b>
                  {{ $t('lang.buyers.find_the_right_provider') }}
                </b>
              </h3>

              <p class="text">
                {{ $t('lang.buyers.have_you_thought_that_when_you_look_for_nails_you_could_say_nails?') }}
              </p>

              <p class="text">
                {{ $t('lang.buyers.we_use_unspsc_to_identify_products_and_services') }}
                <br>
                <b class="info_title">
                  {{ $t('lang.buyers.this_is_the_future_of_shopping!') }}
                </b>
              </p>
              <h4 class="title space-title">
                <b>
                  {{ $t('lang.buyers.manage_your_purchases') }}
                </b>
              </h4>

              <p class="text">
                {{ $t('lang.buyers.at_handelbay_you_receive_requests_from_the_other_areas_of_your_company') }}
              </p>

              <p class="text space-title">
                {{ $t('lang.buyers.you_can_also_generate_reports_do_postpurchase_monitoring_and_have_all_the_traceability') }}
                <br>
                <b class="info_title">
                  {{ $t('lang.buyers.you_can_do_it_all_in_one_place!') }}
                </b>
              </p>
            </div>
          </div>
          <div class="col-md-4 col-sm-5 col-xs-6 col-xs-offset-3 col-sm-offset-0">
            <div class="brand">
              <img src="../assets/images/landing/handelbay/handelbay_man2_delivery.png" alt="Es hora de usar HandelBay en tu día a día">
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Compara cotizaciones y proveedores -->
    <!-- .handelbay-background-curva -->
    <section id="handelbay-background-curva-bottom">
    </section>
    <!-- .handelbay-background-curva -->
    <!-- Listo para innovar -->
    <section id="ready_to_innovate">
      <div class="container">
        <div class="title-footer text-center">
          <h2 class="title">
            {{ $t('lang.buyers.are_you_ready_to_innovate_in_your_shopping_area?') }}
          </h2>
          <router-link to="/tarifas" class="btn btn-handelbay-success" @click.native="scrollUp">
            {{ $t('lang.buyers.see_buyer_plans') }}
          </router-link>
        </div>
      </div>
    </section>
    <!-- End Listo para innovar -->
    <!-- .handelbay-background-curva -->
    <section id="handelbay-background-curva">
    </section>
    <!-- .handelbay-background-curva -->
    <!-- Optimiza tus compras -->
    <section id="optimize_your_purchases">
      <div class="container">
        <div class="row">
          <div class="col-md-10 col-sm-12 col-md-offset-1 text-left">
            <div class="title" itemscope itemtype="https://handelbay.com/">
              <h2 class="title-1" itemprop="HandelBay seguridad">
                {{ $t('lang.buyers.optimize') }}
              </h2>

              <h3 class="title-2" itemprop="HandelBay transparancia">
                {{ $t('lang.buyers.your_shopping') }}
              </h3>
            </div>
            <div class="handelbay-text" itemscope itemtype="https://handelbay.com/">
              <p itemprop="HandelBay es la solución">
                {{ $t('lang.buyers.in_four_large_groups_you_will_be_able_to_understand_all_the_characteristics_what_handelbay_offers') }}
                <br>
                {{ $t('lang.buyers.for_purchasing_areas_regardless_of_the_size_of_the_company') }}
              </p>
            </div>
            <div class="img_buyers">
              <img src="../assets/images/landing/handelbay/handelbay_optimiza_compras.png" alt="Optimiza tus compras">
            </div>
          </div>
        </div>
        <!-- .row -->
        <div class="title_content text-center">
          <h2 class="title">
            {{ $t('lang.buyers.more_providers_better_options') }}
            <i class="fa fa-caret-right"></i>
          </h2>
        </div>
        <div class="row">
          <div class="col-md-10 col-sm-12 col-md-offset-1 text-left">
            <div class="title" itemscope itemtype="https://handelbay.com/">
              <h2 class="title-1" itemprop="HandelBay seguridad">
                {{ $t('lang.buyers.features_for') }}
              </h2>

              <h3 class="title-2" itemprop="HandelBay transparancia">
                {{ $t('lang.buyers.buyers') }}
              </h3>
            </div>
            <Functionalities />
            <div class="title-footer text-center">
              <h4 class="title">
                <b>
                  <em>
                    {{ $t('lang.buyers.i_want_to_be_part_of_handelbay!') }}
                  </em>
                </b>
              </h4>
              <a :href="global.sign_up" class="btn btn-handelbay-success btn-handelbay-black" style="margin-top:15px !important;">
                {{ $t('lang.buyers.sign_up_for_free_now') }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- .row -->
    <!-- .container -->
    </section>
    <!-- End Optimiza tus compras -->
    <!-- .handelbay-background-curva -->
    <section id="handelbay-background-curva-bottom">
    </section>
    <!-- .handelbay-background-curva -->
    
    <PorqueHandelbay />
    <ModalTuDemo />
    <ModalPlans />
  </div>
</template>
<script>
import global from "@/components/Global.vue"
import Functionalities from "@/components/FunctionalitiesBuyer.vue"
import ModalPlans from "@/components/ModalPlans.vue"
import PorqueHandelbay from "@/components/PorqueHandelbay.vue"
import ModalTuDemo from "@/components/ModalTuDemo.vue"

export default {
  components:{
    Functionalities,
    ModalPlans,
    PorqueHandelbay,
    ModalTuDemo
  },
  data(){
    return{
      global: global
    }
  },
  methods:{
    scrollUp: () =>{
      window.scroll({top:0})
    }
  }
}
</script>